<footer class="main-footer">
    <article class="container-new">
        <div class="row">

            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                <div class="footer-company-details">
                    <img src="../../../../assets/images/Newui-image/logo-2.png" class="img-fluid footerLogo" alt="Brand Footer Logo"/>
                    <p class="footerline my-3">
                        GiveAnywhere is the ultimate platform for raising funds for your organization or cause.
                    </p>
                    <div class="social-footer-menu d-flex align-items-center">
                        <a ><i class="bi bi-facebook"></i></a>
                        <a ><i class="bi bi-twitter-x"></i></a>
                        <a href="https://www.linkedin.com/company/giveanywhere/" target="_blank"><i class="bi bi-linkedin"></i></a>
                        <a href="https://www.youtube.com/@GiveAnywhere" target="_blank"><i class="bi bi-youtube"></i></a>
                        <a ><i class="bi bi-instagram"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-6">
                <h5 class="footer-title">
                    Company <span class="light-text">Link</span>
                </h5>
                <div class="footer-list">
                    <a  [routerLink]="['home']">Home</a>
                    <!-- <a >Solution</a> -->
                    <a routerLink='aboutus'>About</a>
                    <!-- <a routerLink='pricing'>Pricing</a>
                    <a routerLink='contactus'>Contact</a> -->
                    
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-6 otherCol">
                <h5 class="footer-title">
                    Other Site <span class="light-text">Link</span>
                </h5>
                <div class="footer-list">
                    <a [routerLinkActive]="['active']" [routerLink]="['privacypolicy']">Data Deletion Policy</a>
                    <!-- <a ></a> -->
                    <a routerLink='termsofservice'>Terms and Condition</a>
                    <!-- <a routerLink='contactus'>Need Support?</a> -->
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-12 contactCol-ftr">
                <h5 class="footer-title">
                    Contact <span class="light-text">Link</span>
                </h5>
                <div class="footer-list contact-list-ftr">
                    <a href="tel:(949) 522-6692">(949) 522-6692</a>
                    <a href="mailto:Support@GoGiveAnywhere.com">Support@GoGiveAnywhere.com</a>
                    <a class="none-underline-link">2512 Chambers Rd, Suite #105, Tustin, CA 92780</a>
                </div>
            </div>
        </div>
        
        <hr class="divider-ftr"/>
        <div class="row">
            <div class="col-lg-4 col-md-4  col-sm-12 col-12">
                <a  class="btn custom-btn pinkBtn" (click)="signup()" >Create Free Account now</a>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12 col-12">
                <p class="footerline text-end copyRightText">© 2023 All Rights Reserved By GiveAnywhere.</p>
            </div>
        </div>
    </article>
</footer>