import { Component } from '@angular/core';
import { environment } from 'src/environments/environment.stage';

@Component({
  selector: 'app-new-ui-footer',
  templateUrl: './new-ui-footer.component.html',
  styleUrls: ['./new-ui-footer.component.css']
})
export class NewUiFooterComponent {

  signup(){
    let URL = environment.website_domain
    let redirectingURL = `https://` + URL + `.com/signup`
    window.open(redirectingURL, "_blank");
  }
}
