import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../../services/campaign.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  orgAboutUs: any;
  organizationid: any;
  qrcode_id: any;

  constructor(private campaignService: CampaignService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.fetchandSetInfo();
    this.getAboutUs();
  }
  
  fetchandSetInfo(){
    this.qrcode_id =  localStorage.getItem('qrcode_id');
    this.organizationid= localStorage.getItem('organizationid');
    const queryParams: Params = { qrcode_id: this.qrcode_id, organizationid: this.organizationid };

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    //localStorage.removeItem('qrcode_id');
    //localStorage.removeItem('organizationid');
    //localStorage.setItem('qrcode_id',this.qrcode_id );
    //localStorage.setItem('organizationid',this.organizationid);
  }
  getAboutUs() {
    this.campaignService.getAboutUs(this.organizationid).subscribe(res => {
      this.orgAboutUs = res;
    });
  }
}
