// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: "https://6op50ujgu6.execute-api.us-east-1.amazonaws.com/dev",
  domain:'stage.campaign',
  website_domain:'stage.gogiveanywhere',
  domain_url: 'https://stage.campaign.gogiveanywhere.com',
  awsCredentials: {
    region: "us-east-1",
    accessKeyId: 'AKIASDYGHITESNFTYSYX',
    secretAccessKey: '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',
    database: "giveanywheredev"
  },
  paymentIntakeUrl:"https://stage.givenow.gogiveanywhere.com/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
