import { Component } from '@angular/core';

@Component({
  selector: 'app-data-deletion-policy',
  templateUrl: './data-deletion-policy.component.html',
  styleUrls: ['./data-deletion-policy.component.css']
})
export class DataDeletionPolicyComponent {

}
