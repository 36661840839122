<div class=" left_lines  ">
    <div class="container-80  flex-column mt-20">


        <div class="fw-bold fs-2 mt-20">
            Data Retention Policy for GiveAnywhere Platform Operated by GiveAnywhere LLC
        </div>
        <div class="sub-title mt-20 fw-bold fs-5">
            Effective Date: 06/01/2023
        </div>
        <div class="description mt-20">
            <div>
                GiveAnywhere LLC, operating the GiveAnywhere platform ("we," "us," or "our"), recognizes the
                importance of data retention and the responsible management of user information. This Data Retention
                Policy outlines our practices regarding the retention and deletion of data collected through the
                GiveAnywhere platform.
            </div>
            <br />
            <div>
                <div class="sub-title mt-20 fw-bold">
                    Purpose and Scope
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">1.1 This Data Retention Policy applies to all personal and
                            non-personal
                            information collected through
                            the GiveAnywhere platform. It provides guidelines for the retention and deletion of data to
                            ensure
                            compliance with legal obligations, business requirements, and user expectations.</li>
                    </ol>



                </div>
            </div>
            <br />
            <div>
                <div class="sub-title mt-20 fw-bold">
                    Data Retention Principles
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">2.1 Minimization: We retain data only for as long as it is
                            necessary for the purposes for which it
                            was collected and to fulfill our legal obligations.</li>
                        <li class="list-group-item">2.2 Lawful Basis: We retain data in compliance with applicable
                            laws, regulations, contractual
                            obligations, and legitimate business interests.</li>
                        <li class="list-group-item">2.3 Data Categories: We categorize data based on its type and
                            sensitivity, applying different
                            retention periods accordingly.</li>
                    </ol>
                </div>
            </div>
            <br />

            <div>
                <div class="sub-title mt-20 fw-bold">
                    Data Retention Periods
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">3.1 Personal Information: We retain personal information for as
                            long as necessary to fulfill the
                            purposes for which it was collected, to provide the requested services, and to comply with
                            legal
                            requirements. The specific retention periods may vary depending on factors such as the
                            nature of the
                            data, the applicable legal obligations, and any contractual or business considerations.
                            Examples of
                            personal information and associated retention periods include:
                            <ol class="list-group ">
                                <li class="list-group-item"> Account Information: We retain account information for as
                                    long as the user maintains an
                                    active
                                    account. If an account is inactive or closed, we may retain the information for a
                                    reasonable
                                    period
                                    to facilitate reactivation or as required by law.
                                </li>
                                <li class="list-group-item"> Donation Information: We retain donation information for a
                                    period necessary to process the
                                    donation,
                                    provide tax receipts or acknowledgments, and comply with financial, accounting, and
                                    tax
                                    regulations.</li>
                                <li class="list-group-item">Communication Data: We retain communication data, such as
                                    emails or messages, for a
                                    reasonable
                                    period necessary to address user inquiries, provide support, or maintain a record of
                                    interactions.</li>
                            </ol>
                        </li>
                        <li class="list-group-item"> 3.2 Non-Personal Information: We may retain non-personal
                            information, such as aggregated or
                            anonymized data, for an indefinite period, as long as it does not identify individuals or
                            violate
                            their privacy rights.</li>
                    </ol>
                </div>
                <br />
            </div>
            <div>
                <div class="sub-title mt-20 fw-bold">
                    Data Deletion and Disposal
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">4.1 Secure Deletion: When data reaches the end of its retention
                            period or is no longer necessary
                            for the purposes for which it was collected, we will securely delete or anonymize the data,
                            rendering it non-retrievable.</li>
                        <li class="list-group-item"> 4.2 Data Disposal: We use appropriate measures to dispose of data
                            in a manner that ensures it
                            cannot be accessed or reconstructed. Disposal methods may include permanent deletion from
                            our
                            systems, shredding physical documents, or securely wiping electronic storage media.</li>
                    </ol>
                </div>
            </div>
            <br />
            <div>
                <div class="sub-title mt-20 fw-bold">
                    User Rights
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">5.1 Users have certain rights regarding their personal information,
                            including the right to access,
                            rectify, and delete their data. If you would like to exercise these rights or have any
                            questions
                            regarding our data retention practices, please refer to our Privacy Policy or contact us
                            using the
                            information provided below.</li>
                    </ol>
                </div>
                <br />
            </div>
            <div>
                <div class="sub-title mt-20 fw-bold">
                    Changes to this Data Retention Policy
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">6.1 We may update this Data Retention Policy as necessary to reflect
                            changes in our data retention
                            practices or legal requirements. We will notify users of any material changes by posting the
                            updated
                            policy on the GiveAnywhere platform or through other means of communication.</li>
                    </ol>

                </div>
            </div>

            <br />
            <div>
                <div class="sub-title mt-20 fw-bold">
                    Contact Us
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">If you have any questions, concerns, or requests regarding this Data
                            Retention Policy or our data
                            retention practices, please contact us at <br>GiveAnywhere LLC <br>2512 Chambers Rd, Suite # 105 <br>Tustin - CA
                            92780</li>
                    </ol>
                </div>
            </div>
            <br />
            <div>
                <div class="mt-20  pb-lg-5">
                    By using the GiveAnywhere platform, you acknowledge that you have read and understood this Data
                    Retention Policy and consent to the retention and disposal of your data as described herein.
                </div>

            </div>
        </div>
    </div>
</div>