import { Injectable } from '@angular/core';

declare const FB: any; // Declare the global FB object from the SDK
declare const twitt: any; // Declare the global FB object from the SDK

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  initializeFacebookSDK(): void {
    if (typeof window !== "undefined") {
      (window as any).fbAsyncInit = () => {
        FB.init({
          // appId: '241292818602606',
          appId: "818089353281779",
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v12.0',
          status : true, // check login status
          cookie : true, // enable cookies to allow the server to access the session
        });
      };

      // Load the Facebook SDK asynchronously
      ((d, s, id) => {
        let js: any,
          fjs: any = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  }

  share(url: string): void {
    FB.ui({ method: 'share', href: url }, (response: any) => {
      // Handle the share dialog response if needed
    });
  }


  initializeTwitterSDK() {

    ((d, s, id) => {

      let js: any,
        fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);

    })(document, 'script', 'twitter-jssdk')
  }

  shareTwitter(url: string): void {
    twitt.ui({ method: 'share', href: url }, (response: any) => {
      // Handle the share dialog response if needed
    });

  }
}
