<div class=" left_lines  ">
    <div class="container-80  flex-column mt-20">


        <div class="fw-bold fs-2 mt-20">
            Privacy Policy for GiveAnywhere Platform Operated by GiveAnywhere LLC
        </div>
        <div class="sub-title fw-bold mt-20 fw-bold fs-5">
            Effective Date: 06/01/2023
        </div>
        <div class="description mt-20">
            <div>
                GiveAnywhere LLC, operating the GiveAnywhere platform ("we," "us," or "our"), we are
                committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use,
                store, and disclose information when you access or use our website, mobile applications, and other
                related services (collectively, the "Services"). By using our Services, you consent to the collection
                and use of your information as described in this Privacy Policy.
            </div>
            <br />
            <div>
                <div class="sub-title fw-bold mt-20">
                    Information We Collect
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">1.1. Personal Information: We may collect personal information that
                            can be used to identify you,
                            such as
                            your name, email address, postal address, phone number, and payment details when you
                            register an
                            account
                            or make a donation through our platform. We will only collect personal information that is
                            relevant
                            to
                            the purpose for which it is being collected.</li>
                        <li class="list-group-item"> 1.2. Non-Personal Information: We may also collect non-personal
                            information, such as your IP
                            address,
                            browser type, device information, and usage data. This information is collected
                            automatically and is
                            used to improve our Services, analyze trends, and administer the platform.</li>
                    </ol>
                </div>
            </div>
            <br />
            <div>
                <div class="sub-title fw-bold mt-20">
                    How We Use Your Information
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">2.1. Provide and Improve Services: We use the information we collect
                            to provide and improve our
                            Services, personalize your experience, respond to your inquiries, process donations, and
                            communicate
                            with you about your account and our platform.</li>
                        <li class="list-group-item">2.2. Security: We may use your information to detect and prevent
                            fraudulent or unauthorized
                            activities, ensure the security of our Services, and comply with legal obligations.</li>
                        <li class="list-group-item">2.3. Analytics: We may analyze the usage patterns and behavior of
                            our users to better understand how
                            our platform is being used, identify areas for improvement, and enhance the overall user
                            experience.</li>
                        <li class="list-group-item"> 2.4. Communications: We may use your information to send you
                            relevant notifications, updates, and
                            promotional materials. You can opt-out of receiving marketing communications by following
                            the
                            unsubscribe instructions provided in the message.</li>
                    </ol>
                </div>
            </div>
            <br />
            <div>
                <div class="sub-title fw-bold mt-20">
                    Information Sharing and Disclosure
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">3.1. Service Providers: We may share your information with trusted
                            third-party service providers
                            who assist us in operating our Services, such as payment processors, hosting providers, and
                            customer support services. These providers are bound by confidentiality obligations and are
                            prohibited from using your information for any other purpose.</li>
                        <li class="list-group-item">3.2. Legal Compliance: We may disclose your information if required
                            by law, government request,
                            or when we believe in good faith that disclosure is necessary to protect our rights, enforce
                            our
                            policies, respond to claims, or comply with a judicial proceeding, court order, or legal
                            process.</li>
                        <li class="list-group-item">3.3. Aggregated and Non-Personal Information: We may share
                            aggregated and non-personal
                            information with third parties for various purposes, including analytics, research,
                            marketing,
                            and other business purposes.
                        </li>
                    </ol>
                </div>
                <br />
            </div>
            <div>
                <div class="sub-title fw-bold mt-20">
                    Data Security
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">4.1 We take reasonable measures to protect the information we
                            collect from loss, unauthorized
                            access, disclosure, alteration, or destruction. However, no method of transmission over the
                            Internet or electronic storage is 100% secure. Therefore, while we strive to use
                            commercially acceptable means to protect your information, we cannot guarantee its absolute
                            security.</li>

                    </ol>

                </div>
            </div>
            <br />
            <div>
                <div class="sub-title fw-bold mt-20">

                    Your Choices and Rights
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">5.1 You have certain rights regarding the personal information we
                            hold about you. You may
                            update or delete your account information by accessing your account settings. You may
                            also request access, correction, or deletion of your personal information by contacting
                            us using the information provided at the end of this Privacy Policy.</li>
                    </ol>

                </div>
                <br />
            </div>
            <div>
                <div class="sub-title fw-bold mt-20">
                    Third-Party Links and Services
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">6.1 Our Services may contain links to third-party websites or
                            services that are not
                            operated or controlled by us. This Privacy Policy does not apply to those
                            third-party websites or services. We recommend reviewing the privacy policies of
                            those third parties before providing any personal information.</li>
                    </ol>
                </div>
            </div>

            <br />
            <div>
                <div class="sub-title fw-bold mt-20">
                    Children's Privacy
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">7.1 Our Services are not intended for individuals under the age of
                            16. We do not
                            knowingly collect personal information from children. If you are a parent or
                            guardian and believe that your child has provided us with personal information,
                            please contact us, and we will take appropriate steps to remove the information
                            from our systems.</li>
                    </ol>

                </div>

            </div>
            <br />
            <div>
                <div class="sub-title fw-bold mt-20">
                    Changes to this Privacy Policy
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">8.1 We may update this Privacy Policy from time to time to reflect
                            changes in our
                            practices or for other operational, legal, or regulatory reasons. We will notify
                            you of any material changes by posting the updated Privacy Policy on our website
                            or through other means of communication.</li>
                    </ol>

                </div>

            </div>
            <br />
            <div>
                <div class="sub-title fw-bold mt-20">
                    Contact Us
                </div>
                <div>
                    <ol class="list-group">
                        <li class="list-group-item">If you have any questions, concerns, or suggestions regarding this
                            Privacy
                            Policy or our privacy practices, please contact us at <br>
                            GiveAnywhere LLC <br>
                            2512 Chambers Rd, Suite # 105 <br>
                            Tustin, CA 92780</li>
                    </ol>
                </div>
            </div>
            <div>
                <div class="mt-20 pb-lg-5">
                    By using our Services, you acknowledge that you have read and understood this
                    Privacy Policy and consent to the collection, use, and disclosure of your
                    information as described herein.
                </div>
            </div>
        </div>
    </div>
</div>