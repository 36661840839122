import { Directive, ElementRef, HostListener, Input } from "@angular/core";


@Directive({
  selector: "[numeric]"
})
export class NumericDirective {
  @Input("decimals") decimals: number = 0;
  @Input("negative") negative: number = 0;
  @Input("allowZero") allowZero: boolean = true;
  
  private regex: RegExp = new RegExp(/^\d*?\d{0}$/g);
  //private regex: RegExp;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    
    
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
      return;
    }
    if (!this.allowZero) {
      if (next && parseInt(next) == 0) {
        event.preventDefault();
      }
    }

  }


}
