<!-- <div class="info_section ">
    <div class="container" style="height: fit-content;">
        <div class="row">
            <div class="col-md-3">
                <div class="info_info">
                    <div class="logo mb-2">
                        <img src="../../assets/images/Give_Anywhere_Logo_white.png" width="75%" alt="">
                    </div>
                    <div>
                        <p>
                            GiveAnywhere is the ultimate platform for raising funds for your organization or cause.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info_contact justify-content-center">
                    <h5>
                        Information
                    </h5>
                    <div class="">
                        <div class="img-box number mr-30">
                            <img src="../../assets/images/location.svg" alt="">
                        </div>
                        <p>
                            Address : 2512 Chambers Rd, Suite #105, Tustin, CA 92780
                        </p>
                    </div>
                    <div>
                        <div class="img-box number mr-30">
                            <img src="../../assets/images/call.svg" alt="">
                        </div>
                        <p>
                            ‪(949) 522-6692‬
                        </p>
                    </div>
                    <div>
                        <div class="img-box number mr-30">
                            <img src="./../assets/images/gmail.svg" alt="">
                        </div>
                        <p>
                            sales@aageinc.com
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info_links">
                    <h5>
                        Useful Link
                    </h5>
                    <ul>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLink]="['home']">
                                Home
                            </a>
                        </li>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLink]="['aboutus']">
                                About Us
                            </a>
                        </li>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLink]="['privacypolicy']">
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLink]="['datadeletionpolicy']">
                                Data Deletion Policy
                            </a>
                        </li>
                        <li>
                            <a [routerLinkActive]="['active']" [routerLink]="['termsofservice']">
                              Terms of Service
                            </a>
                          </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info_form">
                    <h5>
                        Social Media
                    </h5>
                    <div class="social_box">
                        <a href="https://www.linkedin.com/company/giveanywhere/" target="_blank">
                            <img src="../../assets/images/linkedin.svg" alt="">
                        </a>
                        <a href="https://www.youtube.com/@GiveAnywhere" target="_blank">
                            <img src="../../assets/images/youtube.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->




<!-- footer section start -->
<footer>
    <div class="container-new">

        <div class="platform">

            <div class="platform-img">
                <img src="../../../assets/images/platform.png" alt="platform"> 
            </div>
                

            <div class="platform-text">
                <div class="title-part" style="color: #0F0E5B;">
                    <h2> GiveAnywhere <strong> Platform </strong> </h2>
                </div>
                <p>With our QR Integration you can easily generate a QR Code that can be scanned from any location. 
                    Our solution makes it easy for your supporters to give from any mobile devices.</p>
                    <div class="btn-new">
                        <li class="general_fund_for_info_btn">Review Platform Features</li>
                    </div>
               
            </div>

        </div>

        <div class="footer-inner">

            <div class="about-footer">
                <img src="../../../assets/images/logo-2.png" alt="logo" style="width: 50%;">
                <p>GiveAnywhere is the ultimate platform for raising funds for your organization or cause.</p>

                <div class="social-links">
                    <ul>
                        <li><a ></a><i class="fa fa-facebook-square" aria-hidden="true"></i></li>
                        <li><a ></a><i class="fa fa-instagram" aria-hidden="true"></i></li>
                        <li><a href="https://www.youtube.com/@GiveAnywhere"></a><i class="fa fa-youtube-play" aria-hidden="true"></i></li>
                        <li><svg style="fill: #00ffd1;" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></li>
                        <li><a href="https://www.linkedin.com/company/giveanywhere/"></a><i class="fa fa-linkedin" aria-hidden="true"></i></li>
                    </ul>
                </div>
             
            </div>


            <div class="links">

                    <div class="contact-details">
                        <ul style="cursor: pointer;">
                            <li><i class="fa fa-phone" aria-hidden="true"></i> ‪(949) 522-6692‬</li>
                            <li><i class="fa fa-envelope" aria-hidden="true"></i><a href="mailto:Support@GoGiveAnywhere.com">Support@GoGiveAnywhere.com</a> </li>
                            <!-- <li><i class="fa fa-envelope" aria-hidden="true"></i> sales&#64;aageinc.com</li> -->
                            <li><i class="fa fa-map-marker" aria-hidden="true"></i> 2512 Chambers Rd, Suite #105, Tustin, CA 92780</li>
                        </ul>
                    </div>

                    <div class="useful-links">
                        <h3>useful links</h3>
                        <ul>
                            <li><a [routerLink]="['home']">Home</a></li>
                            <li><a [routerLink]="['aboutus']">About Us</a></li>
                            <li><a [routerLink]="['contactus']">Contact</a></li>
                            <li><a [routerLink]="['datadeletionpolicy']">Data Deletion Policy</a></li>
                            <li><a [routerLink]="['termsofservice']">Terms of Service</a></li>
                        </ul>
                    </div>

                    <div class="about-company">
                        <p>We Help Everyone Expand Reach, Reduce Friction, Lower Cost and Increase campaign outcome.</p>
                        <div class="btn-new">
                            <a [routerLink]="['signup']">Sign Up & Get Started Today <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                        </div>
                    </div>

            </div>

        </div>

    </div>

    <div class="footer-bottom">
        <p>© 2023 All Rights Reserved By GiveAnywhere.</p>
    </div>

</footer>
<!-- footer section end -->

