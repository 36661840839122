<!-- Header start -->
<header class="header_main">
  <div class="container-new">
    <div class="header_row">
      <div class="header_left_logo">
        <div class="header_left_logo_block" *ngIf="this.campaignsData?.account_type === 'organization'">
          <img [src]="campaignsData?.organization_logo" alt="">
        </div>

        <div class="header_left_logo_block" *ngIf="this.campaignsData?.account_type !== 'organization'">
          <img [src]="campaignsData?.individual_imageurl" alt="">
        </div>
      </div>
      <div class="header_nav_block" *ngIf="this.campaignsData?.account_type === 'organization'">
        <nav>
          <ul>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['home']"
                 [queryParams]="{qrcode_id: fatchedParams.qrcode_id, organizationid: fatchedParams.organizationid}">Donate</a>
            </li>
            <li *ngIf="common.storeNavigationData?.isStoreNavigationLinkVisible">
              <a [routerLinkActive]="['active']" [routerLink]="common.storeNavigationData?.routerLink"
                 [queryParams]="common.storeNavigationData?.queryParams">Shop</a>
            </li>
            <li *ngIf="common.eventNavigationData?.isEventNavigationLinkVisible">
              <a [routerLinkActive]="['active']" [routerLink]="common.eventNavigationData?.routerLink"
                 [queryParams]="common.eventNavigationData?.queryParams">Events</a>
            </li>
            <li *ngIf="common.raffleNavigationData?.isRaffleNavigationLinkVisible">
              <a [routerLinkActive]="['active']" [routerLink]="common.raffleNavigationData?.routerLink"
                 [queryParams]="common.raffleNavigationData?.queryParams">Raffles</a>
            </li>
            <li *ngIf="common.auctionNavigationData?.isAuctionNavigationLinkVisible">
              <a [routerLinkActive]="['active']" [routerLink]="common.auctionNavigationData?.routerLink"
                 [queryParams]="common.auctionNavigationData?.queryParams">Auction</a>
            </li>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['aboutus']" [queryParams]="fatchedParams">About Us</a>
            </li>
            <li class="nav-item" style="margin-left: -25px;">
              <a class="nav-link" id="navbarDropdown">My Account</a>
              <div class="dropdown-menus" aria-labelledby="navbarDropdown">
                <div class="dropdown-menu" style="height: 200px;width: 200px;padding: 10px;cursor: auto;"><b>Your account is where you can view all transactions, manage payment methods & recurring donations</b>
                  <a class="dropdown-item nav-item mt-2"  style="text-align: center;background: none;" (click)="signin()">Sign in</a>
                  <a class="dropdown-item nav-item mt-1" style="text-align: center;background: none;" (click)="signup()">Sign Up</a>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <div class="header_nav_block" *ngIf="this.campaignsData?.account_type !== 'organization'">
        <nav>
          <ul>
            <li>
              <a [routerLinkActive]="['active']" [routerLink]="['individualhome']"
                 [queryParams]="{qrcode_id: fatchedParams.qrcode_id, organizationid: fatchedParams.organizationid}">Donate</a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="header_right_logo">
        <div class="header_right_logo_block" style="cursor: pointer;">
          <img src="../../../assets/images/logo-2.png" (click)="redirectToWebsite()">
        </div>
      </div>
      <div class="header_mobile_menu" *ngIf="this.campaignsData?.account_type === 'organization'">
        <div class="header_mobile_menu_block">
          <div id="toggle" #togglebtn (click)="btnClick()" isToggle="true">
            <fa-icon [icon]="faBars" style="color: var(--dark-blue); font-size: 30px;"
                     class="fa-2x"></fa-icon>
          </div>
          <div>
            <ejs-sidebar id="default-sidebar" #sidebar [type]='type'
                         [closeOnDocumentClick]="closeOnDocumentClick" [target]='target'
                         [enablePersistence]='enablePersistence' (created)="onCreated($event)"
                         style="visibility: hidden;">
              <div class="d-flex justify-content-center p-4 mx-2">
                <div class="" style="font-family: 'Times New Roman', Times, serif; font-size: 16px;"
                     (click)="closeClick()" isToggle="true">x</div>
              </div>
              <div class="" style="width: 300px;">
                <nav class="nav flex-column justify-center">
                  <a class="nav-link p-1" (click)="closeClick()" [routerLinkActive]="['active']"
                     [routerLink]="['home']">Donate</a>

                  <ng-container *ngIf="common.storeNavigationData?.isStoreNavigationLinkVisible">
                    <a class="nav-link p-1" (click)="closeClick()" [routerLinkActive]="['active']"
                       [routerLink]="common.storeNavigationData?.routerLink"
                       [queryParams]="common.storeNavigationData?.queryParams">Shop</a>
                  </ng-container>

                  <ng-container *ngIf="common.eventNavigationData?.isEventNavigationLinkVisible">
                    <a class="nav-link p-1" (click)="closeClick()" [routerLinkActive]="['active']"
                       [routerLink]="common.eventNavigationData?.routerLink"
                       [queryParams]="common.eventNavigationData?.queryParams">Events</a>
                  </ng-container>
                  <ng-container *ngIf="common.raffleNavigationData?.isRaffleNavigationLinkVisible">
                    <a class="nav-link p-1" (click)="closeClick()" [routerLinkActive]="['active']"
                       [routerLink]="common.raffleNavigationData?.routerLink"
                       [queryParams]="common.raffleNavigationData?.queryParams">Raffles</a>
                  </ng-container>
                  <ng-container *ngIf="common.auctionNavigationData?.isAuctionNavigationLinkVisible">
                    <a class="nav-link p-1" (click)="closeClick()" [routerLinkActive]="['active']"
                       [routerLink]="common.auctionNavigationData?.routerLink"
                       [queryParams]="common.auctionNavigationData?.queryParams">Auction</a>
                  </ng-container>
                  <a class="nav-link p-1" (click)="closeClick()" [routerLinkActive]="['active']"
                     [routerLink]="['aboutus']" [queryParams]="fatchedParams">About Us</a>
                  <a class="nav-link p-1" (click)="showMyAccountMenu = !showMyAccountMenu" >
                    My Account <fa-icon [icon]="!showMyAccountMenu ? faCaretDown : faCaretUp" class="px-2"></fa-icon>
                  </a>
                  <ng-container *ngIf="showMyAccountMenu"><b style="padding: 10px;text-align: center;">Your account is where you can view all transactions, manage payment methods & recurring donations</b>
                    <a class="nav-link p-1 px-3" (click)="closeClick()" (click)="signin()">Sign in</a>
                    <a class="nav-link p-1 px-3" (click)="closeClick()" (click)="signup()">Sign Up</a>
                    </ng-container>
                </nav>
              </div>

            </ejs-sidebar>
          </div>
        </div>
      </div>
      <div class="header_mobile_menu" *ngIf="this.campaignsData?.account_type !== 'organization'">
        <div class="header_mobile_menu_block">
          <div id="toggle" #togglebtn (click)="btnClick()" isToggle="true">
            <fa-icon [icon]="faBars" style="color: var(--dark-blue); font-size: 30px;"
                     class="fa-2x"></fa-icon>
          </div>
          <div>
            <ejs-sidebar id="default-sidebar" #sidebar [type]='type'
                         [closeOnDocumentClick]="closeOnDocumentClick" [target]='target'
                         [enablePersistence]='enablePersistence' (created)="onCreated($event)"
                         style="visibility: hidden;">
              <div class="d-flex justify-content-center p-4 mx-2">
                <div class="" style="font-family: 'Times New Roman', Times, serif; font-size: 16px;"
                     (click)="closeClick()" isToggle="true">x</div>
              </div>
              <div class="" style="width: 300px;">
                <nav class="nav flex-column justify-center">
                  <a class="nav-link p-1" (click)="closeClick()" [routerLinkActive]="['active']"
                     [routerLink]="['individualhome']">Donate</a>
                </nav>
              </div>

            </ejs-sidebar>
          </div>
        </div>
      </div>
    </div>






  </div>
</header>
<!-- Header End -->
