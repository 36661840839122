<section class="newsong_section">
  <div class="container-new">
      <div class="newsong_row">
          <h1 class="newsong_title">{{orgAboutUs?.name}}</h1>
          <div class="newsong_info">
            <p>About</p>
            <div class="newsong_barcode">
              <!-- <div class="newsong_barcode_img">
                <img [src]="banner_url">
              </div> -->
            </div>
          </div>
      </div>
  </div>
</section>


    <div class="container flex-column">

        <div class="description">


            <!-- <h2>{{orgAboutUs?.name}}</h2> -->
            <!-- <br> -->





            <div>
                <span><b>EIN:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{orgAboutUs?.ein}} </span>
            </div>
            <div>
                <span><b>Email:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{orgAboutUs?.email}} </span>
            </div>
            <div>
                <span><b>Address:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{orgAboutUs?.address}}</span>
            </div>
            <div>
                {{orgAboutUs?.description}}
            </div>
        </div>
    </div>
