import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastMessage, HandleErrors } from '../_helpers/commonfunction';
import { Util } from '../_helpers/util';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  baseUrl = environment.baseUrl;
  constructor(
    private http: HttpClient,
    private route: Router,
    private spinnerService: NgxSpinnerService,
    private authService: AuthenticationService,
    private handleErrors: HandleErrors,
    private toastMessage: ToastMessage
  ) {
  }

  public signIn(request: any): Observable<any> {
    const body = request;
    //return this.http.post(ApiPath.getSignIn(), body).pipe(map(res => res));
    return this.http.post(`${this.baseUrl}/login`, body).pipe(map(res => res));
    //this.http.post(ApiPath.getSignIn(), body).subscribe(
    //  (res: any) => {
    //    this.authService.setSignInResponse(res);
    //    if (res) {

    //    }
    //  },
    //  error => {

    //    this.spinnerService.hide();
    //    this.handleErrors.HandleError(<any>error);
    //  },
    //  () => {
    //    this.spinnerService.hide();


    //  }
    //)


  }



  public signUpUser(request: any): Observable<any> {
    let unSuccess: boolean = false;
    this.spinnerService.show();
    const body = request;
    return this.http.post(`${this.baseUrl}/user`, request).pipe(map(res => res));
    //this.http.post(ApiPath.getUserSignUp(), body, { observe: 'response' }).subscribe(
    //  (res: any) => {
    //    // store account information together in an Account object - res.body is just account id.
    //    // let signUpResponse: SignUpUserResponse = res.body;
    //    let account: any = {};
    //    account.id = res.body;
    //    // account.onboardingUrl = signUpResponse.onboarding_url;
    //    this.authService.setAccount(account);
    //    this.toastMessage.Success("You Signed Up Succesfully", 5000);
    //  },
    //  error => {

    //    this.spinnerService.hide();
    //    this.handleErrors.HandleError(<any>error);
    //  },
    //  () => {
    //    this.spinnerService.hide();
    //  //  this.route.navigate(['emailverification']);
    //  }
    //);


  }

  public verifyCode(code: string): Observable<any> {
    let unSuccess: boolean = false;
    const accountInfo = this.authService.getAccount();
    let data = { account_id: accountInfo.id, code: code }
    return this.http.post(`${this.baseUrl}/${accountInfo.id}/email/verify`, data).pipe(map(res => res));
  }
  public resendVerificationCode(): Observable<any> {
    let data: any
    const accountInfo = this.authService.getAccount();
    return this.http.post(`${this.baseUrl}/${accountInfo.id}/email/sendcode`, data).pipe(map(res => res));

  }

  public forgotPassword(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/password/forgot`, data).pipe(map(res => res));
  }

  public resetPassword(data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/password/forgot`, data).pipe(map(res => res));
  }




  public sendCode(accountid: any): Observable<any> {
    let body = {
      account_id: accountid
    }
    return this.http.post(`${this.baseUrl}/${accountid}/email/sendcode`, body).pipe(map(res => res));
  }

  public getUserProfile(account :any): Observable<any> {
    
    let header = Util.getHttpHeaders(account.token.value);
   
    return this.http.get(`${this.baseUrl}/user/${account.id}/profile`, { headers: header }).pipe(map(res => res));
  }
}
