import { HttpHeaders } from '@angular/common/http';


export class Util {

  static getHttpHeaders(tokenValue: string): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenValue}`,
      'X-Authorization-SP': tokenValue
    };

    return new HttpHeaders(headers);
  }

  static isOrganization(accountType: string): boolean {
    return (accountType === 'organization');
  }

  static isIndividual(accountType: string): boolean {
    return this.isOrganization(accountType);
  }
}  
