import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { User } from '../models/campaign';

@Injectable()
export class AuthenticationService {


  baseUrl = environment.baseUrl;
  logoutEventTrigger: EventEmitter<any> = new EventEmitter<any>();
  constructor(private http: HttpClient,
    private route: ActivatedRoute,

    private router: Router) {
  }
  StorageKey = {
    //TOKEN: 'token',
    ACCOUNT: 'account'
  }

  getAccount() {
    let account: any;
    let accountJson: string = '';
    const storedJson = localStorage.getItem(this.StorageKey.ACCOUNT);
    if (storedJson !== null) {
      accountJson = storedJson;
      account = JSON.parse(atob(accountJson));
    }

    return account;
  }

  isUserLoggedIn() {
    let signedIn = false;
    const account = this.getAccount();
    // if toke exists -> it's signed in. 
    if (account) {
      signedIn = account?.token ? true : false;
    }
    return signedIn;
  }

  setSignInResponse(response: any) {
    if (response) {
      let tokenObj: any = {};
      tokenObj.expiresIn = response.expires_in;
      tokenObj.issueAt = response.time_issue;
      tokenObj.refreshValue = response.refresh_token;
      tokenObj.value = response.token;

      let accountObj: any = {};
      accountObj.id = response.account_id;
      accountObj.type = response.account_type;
      accountObj.status = response.user_account_status;
      accountObj.token = tokenObj;

      if (response.account_type === 'organization') {
        accountObj.name = response.organization;
      }
      else {
        accountObj.name = response.first_name;
      }

      // set account to local storage
      this.setAccount(accountObj);
    }
  }

  setAccount(account: any) {
    localStorage.setItem(this.StorageKey.ACCOUNT, btoa(JSON.stringify(account)));
  }

  onLogout() {
    localStorage.removeItem(this.StorageKey.ACCOUNT);
  }

 
  getLogoutEventTrigger() {
    return this.logoutEventTrigger;
  }

  emitLogoutEventTrigger(event: any) {
    this.logoutEventTrigger.emit(event);
  }
}
