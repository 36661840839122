import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignService } from 'src/app/services/campaign.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../services/authentication.service';
import { CommonService } from '../../services/common.service';
import { faBars, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  fatchedParams: any;
  sub: any
  qrId: any;
  homeActive: boolean = false
  aboutActive: boolean = false
  campaignsData: any;
  storeId: any;
  campaign: any;
  faBars = faBars;
  showMyAccountMenu: boolean = false;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  @ViewChild('sidebar') sidebar?: SidebarComponent;
  public closeOnDocumentClick: boolean = true;
  public type: string = 'Over';
  public target: string = '.content';
  public enablePersistence: boolean = true;

  public onCreated(args: any) {

    (this.sidebar as SidebarComponent).element.style.visibility = '';
  }
  btnClick() {
    (this.sidebar as SidebarComponent).position = "Right";
    this.sidebar?.show();
  }
  closeClick() {
    this.sidebar?.hide();
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private campaignService: CampaignService, public common: CommonService, public authService: AuthenticationService
  ) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.fatchedParams = params;
        if (this.fatchedParams.organizationid) {
          this.getDashboardInfo();
        }
        // if (!this.campaignsData && (this.campaignsData.organization_id != this.fatchedParams.organizationid)) {
        //   this.getIndividualDashboardInfo();
        // }
        else {
          this.common.setUpNavigationMenu(this.campaignsData, "", this.fatchedParams);
        }
      });
    
  }


  getDashboardInfo() {
    this.campaignService.getCampaigns(this.fatchedParams.organizationid, false).subscribe(res => {
      this.campaignsData = res;

      this.common.setUpNavigationMenu(this.campaignsData, "", this.fatchedParams);
    });
  }

  getIndividualDashboardInfo() {
    this.campaignService.getIndividualCampaigns(this.fatchedParams.organizationid, false).subscribe(res => {
      this.campaignsData = res;

      this.common.setUpNavigationMenu(this.campaignsData, "", this.fatchedParams);
    });
  }

  showHomeBgcolor() {
    // this.homeActive = true
    // this.aboutActive = false
  }
  showAboutBgcolor() {
    // this.aboutActive = !this.aboutActive
    //this.homeActive = !this.homeActive
  }

  redirectToWebsite() {
    let URL = environment.website_domain
    let redirectingURL = `https://` + URL + `.com/home`
    window.open(redirectingURL, "_blank");
  }

  onLogout() {
    this.authService.onLogout();
    this.authService.emitLogoutEventTrigger({ logout: true });
  }

  signin(){
    let URL = environment.website_domain
    let redirectingURL = `https://` + URL + `.com/login`
    window.open(redirectingURL, "_blank");
  }

  signup(){
    let URL = environment.website_domain
    let redirectingURL = `https://` + URL + `.com/individualsignup`
    window.open(redirectingURL, "_blank");
  }
  
}
