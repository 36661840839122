export const environment = {
  production: false,
  baseUrl: "https://6op50ujgu6.execute-api.us-east-1.amazonaws.com/dev",
  domain: 'stage.campaign',
  website_domain: 'stage.gogiveanywhere',
  domain_url: 'https://stage.campaign.gogiveanywhere.com',
  awsCredentials: {
    region: "us-east-1",
    accessKeyId: 'AKIASDYGHITESNFTYSYX',
    secretAccessKey: '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',
    database: "giveanywheredev"
  },
  paymentIntakeUrl: "https://stage.givenow.gogiveanywhere.com/"
};
